var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex landing__programsWrapper"},[_c('div',{staticClass:"landing__programs landing__programsInner"},_vm._l((_vm.templates),function(template,index){return _c('v-btn',{key:index,staticClass:"font-weight-bold",attrs:{"dark":"","color":_vm.currentTemplate.templateName === template.templateName ? _vm.colors[index] : "black","depressed":""},on:{"click":function($event){return _vm.loadTemplate(template)}}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-telegram")]),_vm._v(" "+_vm._s(template.templateName)+" ")],1)}),1)]),_c('div',{staticClass:"mobile-filters"},[_c('div',{staticClass:"d-flex filter-search"},[_c('div',{staticClass:"onClick_iconOpen",on:{"click":function($event){_vm.isAddClass = !_vm.isAddClass}}},[_c('img',{attrs:{"src":require("@/assets/icon-filter.svg"),"alt":"icon filter"}})]),_c('div',{staticClass:"onClick_searchOpen"},[_c('ATextInput',{attrs:{"styling":{
            class: 'login__input',
            outlined: true,
            rounded: true,
            xLarge: true,
            depressed: true,
            fullWidth: true,
            label: 'Search Programs',
            placeholder: 'Search Programs...',
            color: 'white'
          },"single-line":"","dark":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]),_c('div',{staticClass:"d-flex landing__filtersWrapper"},[_c('div',{staticClass:"landing__filters d-flex flex-row"},_vm._l((_vm.pathwaysList),function(cat){return _c('div',{key:cat.name,staticClass:"d-flex flex-column pathway-button justify-center pa-2 pathway-button-text",class:_vm.selectedPathways.includes(cat) ? "selected-pathway" : "not-selected",on:{"click":function($event){return _vm.filterPathways(cat)}}},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":cat.color}},[_vm._v(_vm._s(cat.icon))])],1),_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(cat.abbrName)+" ")])])}),0)]),_c('div',{staticClass:"explore d-flex"},[_c('div',{class:_vm.isAddClass ? 'guide__bar active' : 'guide__bar'},[_c('guide-bar',{ref:"sidebar",staticClass:"guide__bar2",attrs:{"pathways-filter":_vm.pathwaysFilter,"search-program":_vm.searchProgram,"program-filter":_vm.programFilter,"is-searching":_vm.isSearching,"reset-filters":_vm.resetFilters,"age-filter":_vm.ageFilter,"residence-options":_vm.residenceOptions},on:{"update:programFilter":function($event){_vm.programFilter=$event},"update:program-filter":function($event){_vm.programFilter=$event},"update:ageFilter":function($event){_vm.ageFilter=$event},"update:age-filter":function($event){_vm.ageFilter=$event},"update:residenceFilter":function($event){_vm.residenceFilter = $event},"update:pathwaysFilter":function($event){_vm.pathwaysFilter = $event},"reload-programs":_vm.reloadPrograms,"filter-industry":_vm.filterIndustry,"toggle-sidebar":_vm.toggleSidebar,"removeClass":function($event){_vm.isAddClass = false;
          _vm.resetSearch();}}})],1),_c('div',{staticClass:"w-100"},[(_vm.isEdit)?_c('div',{staticClass:"d-flex justify-space-between ma-6"},[_c('div',{staticClass:"onClick_searchOpen"},[_c('ATextInput',{attrs:{"styling":{
              class: 'login__input',
              outlined: true,
              rounded: true,
              xLarge: true,
              depressed: true,
              fullWidth: true,
              label: 'Search Programs',
              placeholder: 'Search Programs...',
              color: 'white'
            },"single-line":"","dark":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"d-flex flex-column align-end"},[_c('v-btn',{staticClass:"mr-3 login__loginBtn",attrs:{"depressed":"","color":"blue","dark":"","large":"","rounded":"","loading":_vm.isSaveSelectedProgramLoading,"disabled":_vm.isFetching},on:{"click":_vm.saveSelectedProgram}},[_c('span',{staticClass:"font-weight-black"},[_vm._v("Save")])])],1)]):_vm._e(),_c('div',{staticClass:"guide__table w-100"},[(_vm.isFetching)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.limit),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"ma-8",staticStyle:{"background":"darkgray"},attrs:{"width":"300","type":"image, image, article"}})}),1):_c('ListView',_vm._g({attrs:{"employers":_vm.filteredPrograms,"is-fetching":_vm.isFetching,"is-sidebar":_vm.isSidebar,"bookmarks":_vm.bookmarked,"is-edit":_vm.isEdit},on:{"updateSelectedEmployerList":_vm.updateSelectedEmployerList,"bookmarkProgram":_vm.bookmarkProgram}},_vm.$listeners))],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }