





































import { ref, PropType, watch, watchEffect } from '@vue/composition-api';
import { ObjectId } from 'bson';
import { LIST_VIEW_ITEMS, LIST_VIEW_HEADER } from '@/constants/explore';
import ExploreCard from './ExploreCard.vue';

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'pink'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea']
];

export default {
  name: 'TableView',
  components: {
    ExploreCard
  },
  props: {
    employers: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>
    },
    bookmarks: {
      required: true,
      type: Array as PropType<Array<ObjectId>>
    },
    isFetching: {
      type: Boolean,
      default: false
    },
    isSidebar: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const grid = ref(3);
    watchEffect(() => {
      if (props.isSidebar) {
        grid.value = 3;
      } else {
        grid.value = 4;
      }
    });
    const toggleSelection = id => {
      ctx.emit('updateSelectedEmployerList', id);
    };
    return {
      grid,
      header: ref(LIST_VIEW_HEADER),
      items: ref(LIST_VIEW_ITEMS),

      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 15, 0],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,
      carousel: [
        {
          src: 'https://picsum.photos/510/1000?random'
        },
        {
          src: 'https://picsum.photos/510/1000?random'
        },
        {
          src: 'https://picsum.photos/510/1000?random'
        },
        {
          src: 'https://picsum.photos/510/1000?random'
        }
      ],
      toggleSelection
    };
  }
};
