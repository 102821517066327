


































































































































































































































































import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { ObjectId } from 'bson';
import axios from 'axios';
import { useUserState, useUserActions, useUserGetters } from '@/store';

const colors = ['red', 'purple', 'orange', 'blue', 'green', 'pink', 'yellow'];

export default defineComponent({
  name: 'ExploreCard',
  props: {
    employer: {
      required: true,
      type: Object as PropType<Record<string, any>>
    },
    bookmarks: {
      required: true,
      type: Array as PropType<Array<ObjectId>>
    },
    isEdit: {
      required: false,
      type: Boolean
    }
  },
  setup(props, ctx: any) {
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const { fetchUserType } = useUserActions(['fetchUserType']);
    const isCreating = ref(false);
    const ifUserIsOrganizer = props?.employer?.organizers?.findIndex(program => {
      return program.userId === user?.value?._id.toString();
    });
    const isChecked = ref(props.employer.sponsored || ifUserIsOrganizer !== -1);
    const isDefaultSelected = ref(ifUserIsOrganizer !== -1);
    const color = colors[Math.floor(Math.random() * colors.length)];
    const bookmarked = computed(() =>
      props.bookmarks?.some(id => id.toString() === props.employer?._id?.toString())
    );
    const isProgramStarted = computed(() => {
      return (
        user?.value &&
        props.employer?.participants?.find(
          org => org?.userId?.toString() === user?.value?._id.toString()
        )
      );
      // return props.employer?.participants?.some((id: ObjectId) => id?.equals(user.value?._id));
    });

    const isOrganizer = computed(() => {
      return (
        user?.value &&
        props.employer?.organizers?.find(
          org => org?.userId?.toString() === user?.value?._id.toString()
        ) &&
        props.employer.published
      );
      // return (
      //   props.employer?.organizers?.some((id: ObjectId) => id?.equals(user.value?._id)) &&
      //   props.employer.published
      // );
    });
    // const { emit } = defineEmits(['toggleSelection']);

    const moveToDetailsPage = (data: any) => {
      const url = data?.sharable_url;
      if (url) {
        const sharableProgramName = url.split('/')[2];
        ctx.root.$router.push({
          name: 'Details',
          params: {
            name: sharableProgramName,
            schemaType: 'program',
            isTemplate: false
          }
        });
      }
    };
    const redirectToGuide = (data: any) => {
      const url = data?.sharable_url;
      if (ctx?.root?.$route?.params?.shareCode && url) {
        const sharableProgramName = url.split('/')[2];
        return {
          name: 'program',
          params: {
            program_name: sharableProgramName,
            type: 'program'
          }
        };
      }
      return {
        name: 'guide',
        params: {
          programId: data._id.toString(),
          page: 0
        }
      };
    };
    const isExternShip = computed(() => {
      let externShip = false;
      // const offer = props.employer?.adks?.find(a => a.name === 'offer');
      const offer = props?.employer?.adks?.find(adk =>
        adk.fields?.find(field => field.name === 'm-offer-table')
      );
      if (offer) {
        externShip = offer.offer ? offer.offer[0]?.externShip : false;
      }
      return externShip;
    });
    const bookmarkProgram = () => {
      ctx.emit('bookmarkProgram', props.employer._id);
    };

    async function createStudentDoc(data) {
      try {
        const url = data?.sharable_url;
        if (ctx?.root?.$route?.params?.shareCode && url) {
          const sharableProgramName = url.split('/')[2];
          ctx.root.$router.push({
            name: 'program',
            params: {
              program_name: sharableProgramName,
              type: 'program'
            }
          });
        } else if (!localStorage.getItem('apollo-token')) {
          // localStorage.setItem(
          //   'setRoute',
          //   `/guide/${props.employer._id.toString()}/0?preview=true`
          // );
          ctx.root.$router.push({ name: 'login' });
        } else {
          isCreating.value = true;
          const data = {
            program_id: props.employer._id.toString()
          };
          const res = await axios.post(`${process.env.VUE_APP_STUDENT_SERVICE}/student`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          });

          if (res.data.data) {
            await fetchUserType({
              program_id: props.employer._id.toString()
            });
            ctx.root.$router.push({
              name: 'guide',
              params: {
                programId: props.employer._id.toString(),
                page: 0
              },
              query: {
                preview: 'true'
              }
            });
          }
        }
      } catch (error) {
        console.log(error);
        isCreating.value = false;
      }
    }

    const checkBoxClick = () => {
      isChecked.value = !isChecked.value;
      ctx.emit('toggleSelection');
    };

    return {
      color,
      bookmarkProgram,
      bookmarked,
      createStudentDoc,
      isCreating,
      isProgramStarted,
      isOrganizer,
      isExternShip,
      moveToDetailsPage,
      redirectToGuide,
      isChecked,
      checkBoxClick,
      isDefaultSelected
    };
  }
});
