





































































































































































import {
  ref,
  reactive,
  toRefs,
  computed,
  defineComponent,
  Ref,
  onMounted,
  watch
} from '@vue/composition-api';
import { ObjectId } from 'bson';
import axios from 'axios';
import _ from 'lodash';
import { useUserState, useUserActions, useProgramActions, useProgramState } from '@/store';
import Bar from '@/views/Explore/Sidebar.vue';
import ListView from '@/views/Explore/components/TableView.vue';
import ATextInput from '@/components/atoms/ATextInput.vue';

export default defineComponent({
  name: 'ExploreGuide',
  components: {
    'guide-bar': Bar,
    ListView,
    ATextInput
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: ''
    },
    shareCode: {
      type: String,
      required: false,
      default: ''
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(_props: any, ctx: any) {
    const { user }: any = useUserState(['user']);
    const employerData: Ref<any[]> = ref([]);
    const { fetchTemplates, fetchPathways, fetchAllPublishedPrograms, saveSearchPreference } =
      useProgramActions([
        'fetchTemplates',
        'fetchPathways',
        'fetchAllPublishedPrograms',
        'saveSearchPreference'
      ]);
    const { templateList, pathwaysList }: any = useProgramState(['templateList', 'pathwaysList']);
    const { getVisitedStudents } = useUserActions(['getVisitedStudents']);
    const state = reactive({
      programFilter: 'All' as 'All' | 'Bookmarked',
      ageFilter: null as any,
      residenceFilter: null,
      pathwaysFilter: [{} as any],
      bookmarked: [] as ObjectId[],
      residenceOptions: [] as string[]
    });
    const skip = ref(0);
    const limit = ref(100);
    const isFetching = ref(false);
    const allProgramsFetched = ref(false);
    const categories = ref(pathwaysList.value);
    const selectedPathways = ref([]);
    const selectedIndustries = ref([]);
    const isSearching = ref(false);
    const currentTemplate: any = ref({});
    const colors = ['blue', 'green', 'purple'];
    const sidebar = ref();
    const isSearched = ref(false);
    const isSidebar = ref(true);
    const searchValue = ref();
    const pathwaysDataValue = ref();
    const isAddClass = ref(false);
    const search = ref('');
    const selectedEmployerList: any = ref([]);
    const isSaveSelectedProgramLoading = ref(false);
    function getAllPrograms(val) {
      isSearched.value = false;
      allProgramsFetched.value = false;
      isFetching.value = true;
      const API_ENDPOINT = process.env.VUE_APP_PUBLISHED_PROGRAM_LIST;
      let payload;
      if (val.templateName === 'All') {
        payload = {
          skip: skip.value,
          limit: limit.value
        };
      } else {
        payload = {
          skip: skip.value,
          limit: limit.value,
          templateId: val?._id?.toString()
        };
      }

      if (selectedPathways?.value?.length) {
        payload.pathwayList = JSON.stringify(selectedPathways.value.map(s => s.name));
      }

      if (_props?.isEdit && _props?.shareCode) {
        payload.isEdit = true;
        payload.shareCode = _props?.shareCode;
      }

      if (_props?.shareCode) {
        payload.shareCode = _props?.shareCode;
      }

      if (selectedIndustries?.value?.length) {
        payload.industryList = JSON.stringify(selectedIndustries.value.map(s => s.name));
      }

      if (searchValue?.value) {
        payload.keyword = searchValue?.value;
      }
      if (_props?.userId) {
        payload.userId = _props?.userId;
      }

      const data = { pathways: pathwaysDataValue?.value, payload };
      fetchAllPublishedPrograms(data)
        .then(async response => {
          let visitedStudents = [];
          if (localStorage.getItem('apollo-token')) {
            visitedStudents = await getVisitedStudents();
          }
          if (response?.sponsorProgramIds) {
            selectedEmployerList.value = response?.sponsorProgramIds;
          }
          if (response?.programs.length) {
            response?.programs.forEach(element => {
              employerData.value.push(element);
            });
            if (visitedStudents && visitedStudents.length >= 1) {
              const programIds = visitedStudents.map(student => student.program_id);
              employerData.value.forEach((element, index) => {
                if (programIds.includes(element._id)) {
                  employerData.value[index].viewed = true;
                }
              });
            }
            const searchPayload = payload;
            delete searchPayload.skip;
            delete searchPayload.limit;
            if (
              skip.value === 0 &&
              (searchPayload?.pathwayList?.length >= 1 ||
                searchPayload?.industryList?.length >= 1 ||
                searchPayload?.keyword)
            ) {
              saveSearchPreference({
                filters: {
                  templateId: searchPayload?.templateId,
                  pathways: searchPayload?.pathwayList,
                  industries: searchPayload?.industryList,
                  keyword: searchPayload?.keyword
                },
                search_result: response?.programs?.map(program => ({
                  _id: program?._id?.toString(),
                  programName: program?.programName,
                  createdBy:
                    program?.createdBy?.toString() || program?.organizers[0]?.userId?.toString()
                })),
                user_id: user?.value?._id?.toString(),
                email: user?.value?.email?.toString(),
                phone_number: user?.value?.phoneNumber?.toString()
              });
            }
            isFetching.value = false;
          } else {
            allProgramsFetched.value = true;
            isFetching.value = false;
          }
        })
        .catch(error => console.log(error));
    }

    const templates = ref([{ templateName: 'All', value: 'all' }]);

    const updateSelectedEmployerList = id => {
      const arrayIndex = selectedEmployerList.value.indexOf(id);
      if (arrayIndex !== -1) {
        selectedEmployerList.value.splice(arrayIndex, 1);
      } else {
        selectedEmployerList.value.push(id);
      }
    };

    onMounted(async () => {
      isFetching.value = true;
      isSearched.value = false;
      const templateListValue = await fetchTemplates();
      templateListValue?.forEach(element => {
        templates.value.push(element);
      });
<<<<<<< HEAD

      const urlProgram = ctx.root.$route.query.program; // Changed from 'template' to 'program'
      const urlModel = ctx.root.$route.query.model; // Changed from 'templateId' to 'model'
      if (urlProgram && urlModel) {
        const selectedTemplate = templates.value.find(
          t => t.templateName === urlProgram && t._id === urlModel
=======
      if (ctx.root.$route.params.user_id && ctx.root.$route.query.template) {
        currentTemplate.value = templates.value.find(
          template => template?.templateName === ctx.root.$route.query.template
>>>>>>> parent of d4351452... Merge pull request #2407 from PilotCityInc/feature/program-filter-url
        );
      } else {
        currentTemplate.value = templates.value.find(
          template => template?.templateName === 'Build projects to win internships'
        );
      }
      const pathwaysData = await fetchPathways();
      pathwaysDataValue.value = pathwaysData?.pathways;
      getAllPrograms(currentTemplate.value);
    });

    function saveSelectedProgram() {
      isSaveSelectedProgramLoading.value = true;
      const API_ENDPOINT = process.env.VUE_APP_SAVE_PROGRAM_LIST;
      const data = {
        programIds: selectedEmployerList.value,
        shareCode: _props.shareCode
      };
      axios
        .post(`${API_ENDPOINT}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(async res => {
          isSaveSelectedProgramLoading.value = false;
        })
        .catch(error => {
          isSaveSelectedProgramLoading.value = false;
          console.log(error);
        });
    }

    const savedBookmarks = localStorage.getItem('bookmarkedPrograms'); // * grab bookmarks from localStorage
    if (savedBookmarks) state.bookmarked = JSON.parse(savedBookmarks).map(id => new ObjectId(id));

    if (ctx.root.$route.query) {
      let query = ctx.root.$route.query.pathway;
      if (!Array.isArray(query)) query = [query];
      query = query.filter(word => !!word);
      if (!query.length)
        state.pathwaysFilter = [
          {
            text: 'All',
            color: 'grey darken-4'
          }
        ];
      else
        state.pathwaysFilter = query.map(pathway => ({
          text: pathway as string,
          color: 'grey darken-4'
        }));
    }

    const currentUnit = ref(ListView);

    const filteredPrograms = computed(() => {
      let visiblePrograms;
      if (state.programFilter === 'Bookmarked') {
        visiblePrograms = employerData.value.filter((program: Record<string, any>) =>
          state.bookmarked.some((id: ObjectId) => id.equals(program._id))
        );
      } else {
        visiblePrograms = employerData.value;
      }

      if (state.residenceFilter && state.residenceFilter !== 'None') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.requiredResidency && program.requiredResidency.includes(state.residenceFilter)
        );
      }

      if (state.ageFilter && state.ageFilter !== 'All') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.ageRange[0] <= parseInt(state.ageFilter, 10) &&
            program.ageRange[1] >= parseInt(state.ageFilter, 10)
        );
      }

      if (state.pathwaysFilter.length && !state.pathwaysFilter.some(obj => obj.text === 'All')) {
        const filters = state.pathwaysFilter.map(obj => obj.text);
        visiblePrograms = visiblePrograms.filter(
          program => program.pathways && program.pathways.some(pathway => filters.includes(pathway))
        );
      }
      return visiblePrograms;
    });

    const bookmarkProgram = (programId: ObjectId) => {
      if (state.bookmarked.some((id: ObjectId) => id === programId))
        state.bookmarked = state.bookmarked.filter((id: ObjectId) => id === programId);
      else state.bookmarked.push(programId);
      localStorage.setItem('bookmarkedPrograms', JSON.stringify(state.bookmarked)); //* save bookmarks to localStorage
    };

    function filterPathways(val) {
      isSearched.value = false;
      skip.value = 0;
      employerData.value = [];
      if (!selectedPathways.value?.includes(val)) {
        selectedPathways.value = [];
        selectedPathways.value.push(val);
      } else {
        const index = selectedPathways.value.findIndex(s => s === val);
        selectedPathways.value.splice(index, 1);
      }
      getAllPrograms(currentTemplate.value);
    }

    const searchProgram = _.debounce(async val => {
      try {
        isFetching.value = true;
        isSearching.value = true;
        searchValue.value = val;
        skip.value = 0;
        employerData.value = [];
        getAllPrograms(currentTemplate.value);
        isFetching.value = false;
        isSearching.value = false;
      } catch (err) {
        isFetching.value = false;
        isSearching.value = false;
      }
    }, 500);

    // async function loadMorePrograms() {
    //   skip.value += 9;
    //   if (isSearched.value) {
    //     await searchProgram(sidebar.value?.search);
    //   } else {
    //     await getAllPrograms(currentTemplate.value);
    //   }
    // }

    window.onscroll = async () => {
      const bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;
      if (bottomOfWindow && !allProgramsFetched.value) {
        skip.value += 100;
        getAllPrograms(currentTemplate.value);
      }
    };

    function reloadPrograms() {
      isSearched.value = false;
      skip.value = 0;
      employerData.value = [];
      searchValue.value = '';
      getAllPrograms(currentTemplate.value);
    }

    function filterIndustry(val) {
      isSearched.value = false;
      skip.value = 0;
      employerData.value = [];
      selectedIndustries.value = val;
      getAllPrograms(currentTemplate.value);
    }

    function resetFilters() {
      isSearched.value = false;
      currentTemplate.value = templates.value.find(
        template => template?.templateName === 'Build projects to win internships'
      );
      skip.value = 0;
      searchValue.value = '';
      employerData.value = [];
      selectedPathways.value = [];
      selectedIndustries.value = [];
      getAllPrograms(currentTemplate.value);
    }

    function toggleSidebar(val) {
      isSidebar.value = val;
    }

<<<<<<< HEAD
    // First, define updateURL
    const updateURL = template => {
      const currentRoute = ctx.root.$route;
      const newQuery = {
        ...currentRoute.query,
        program: template.templateName, // Changed from 'template' to 'program'
        model: template._id // Changed from 'templateId' to 'model'
      };

      // Remove the old 'template' and 'templateId' query parameters if they exist
      delete newQuery.template;
      delete newQuery.templateId;

      ctx.root.$router
        .push({
          path: currentRoute.path,
          query: newQuery
        })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
    };

    // Then, use it in loadTemplate
=======
>>>>>>> parent of d4351452... Merge pull request #2407 from PilotCityInc/feature/program-filter-url
    const loadTemplate = template => {
      isSearched.value = false;
      currentTemplate.value = template;
      skip.value = 0;
      searchValue.value = '';
      employerData.value = [];
      selectedPathways.value = [];
      selectedIndustries.value = [];
      getAllPrograms(currentTemplate.value);
    };
    function resetSearch() {
      search.value = '';
    }
    watch(search, val => {
      if (val.length > 2) {
        searchProgram(val);
      }

      if (!val.length) {
        reloadPrograms();
      }
    });
    return {
      ...toRefs(state),
      toggleSidebar,
      isSearched,
      reloadPrograms,
      resetFilters,
      // loadMorePrograms,
      filterPathways,
      filteredPrograms,
      filterIndustry,
      searchProgram,
      bookmarkProgram,
      currentUnit,
      snackbar: true,
      skip,
      limit,
      isFetching,
      allProgramsFetched,
      categories,
      templates,
      currentTemplate,
      colors,
      selectedPathways,
      pathwaysList,
      isSearching,
      sidebar,
      getAllPrograms,
      selectedIndustries,
      isSidebar,
      loadTemplate,
      isAddClass,
      search,
      resetSearch,
      updateSelectedEmployerList,
      saveSelectedProgram,
      isSaveSelectedProgramLoading
    };
  }
});
